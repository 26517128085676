import React from "react";
import { FooterExternalLinks } from "./styles.js";
import { FooterLegal } from '../../../styles/shared/typography';

const FooterLinks = ({data}) => {
  return (
    <FooterExternalLinks >
      {data.map((legalLink, i) => (
        <li key={i}>
          <FooterLegal
            data-design-category="bottom_nav_vd"
            to={legalLink.href}
            target={legalLink.newTab ? "_blank" : ''}
            className={legalLink.class}
          >
            {legalLink.label}
          </FooterLegal>
        </li>
      ))}
    </FooterExternalLinks>
  );
};

export default FooterLinks;

